<template>
  <div>
    <v-dialog
      v-model="isEditGallery"
      persistent
      max-width="1100"
    >
      <v-card :loading="imageLoading">
        <v-card-title>
          {{ $t('gallery') }} <span class="error--text">{{ $t("NoteGallery600600") }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <input
              ref="selectImage"
              type="file"
              style="display: none"
              @change="previewFiles"
            />
            <v-col
              v-for="(item,index) in productImage"
              :key="index"
              cols="12"
              md="6"
              lg="4"
            >
              <v-card>
                <v-img
                  :lazy-src="item"
                  :src="item"
                ></v-img>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="ma-2"
                        v-bind="attrs"
                        x-small
                        fab
                        v-on="on"
                        @click="showDetailImage(item)"
                      >
                        <v-icon dark>
                          {{ icons.mdiImageSearchOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("look_at_the_image") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="ma-2"
                        v-bind="attrs"
                        x-small
                        fab
                        v-on="on"
                        @click="showSelectImage(index+1);$refs.selectImage.click()"
                      >
                        <v-icon dark>
                          {{ icons.mdiCloudUploadOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("upload_pictures") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        class="ma-2"
                        v-bind="attrs"
                        x-small
                        fab
                        @click="alertRemoveImage(index+1)"
                        v-on="on"
                      >
                        <v-icon dark>
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("DeleteImage") }}</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:isEditGallery',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowDetailImage">
      <v-img
        :src="urlImage"
        :lazy-src="urlImage"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-dialog>
    <v-dialog
      v-model="isRemoveImage"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="error"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>  {{ $t('confirmDeleteImage') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="removeLoading"
            :disabled="removeLoading"
            @click="removeImage"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isRemoveImage = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiDeleteOutline, mdiCloudUploadOutline, mdiImageSearchOutline, mdiAlertOutline,
} from '@mdi/js'
import promotion from '@/api/WebsiteManageMent/promotion'
import store from '@/store'

export default {
  model: {
    prop: 'isEditGallery',
    event: 'update:isEditGallery',
  },
  props: {
    isEditGallery: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const productImage = ref([])
    const product = ref({})
    const product_image = ref('')
    const blob_image = ref('')
    const urlImage = ref('')
    const isShowDetailImage = ref(false)
    const statusImageId = ref('')
    const imageLoading = ref(true)
    const isRemoveImage = ref(false)
    const removeLoading = ref(false)

    const { promotionGet, promotionGalleryAdd, promotionGalleryRemove } = promotion

    const showSelectImage = id => {
      statusImageId.value = id
    }

    const previewFiles = event => {
      product_image.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(
        event.target.files[event.target.files.length - 1],
      )
      addImage()
    }

    const showDetailImage = url => {
      urlImage.value = url
      isShowDetailImage.value = true
    }

    const addImage = () => {
      const formData = new FormData()
      formData.append('product_id', props.productId)
      formData.append('gallery_image_id', statusImageId.value)
      formData.append('product_image', product_image.value)
      promotionGalleryAdd(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getPromotion()
      })
    }

    const getPromotion = () => {
      imageLoading.value = true
      promotionGet(props.productId).then(res => {
        product.value = res
        productImage.value[0] = res.product_image1 || 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
        productImage.value[1] = res.product_image2 || 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
        productImage.value[2] = res.product_image3 || 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
        productImage.value[3] = res.product_image4 || 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
        productImage.value[4] = res.product_image5 || 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
        productImage.value[5] = res.product_image6 || 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
        imageLoading.value = false
      })
    }

    const alertRemoveImage = id => {
      statusImageId.value = id
      isRemoveImage.value = true
    }

    const removeImage = () => {
      removeLoading.value = true
      promotionGalleryRemove(props.productId, statusImageId.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        isRemoveImage.value = false
        productImage.value[statusImageId.value - 1] = 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg'
        getPromotion()
        removeLoading.value = false
      })
    }

    watch(() => props.isEditGallery, value => {
      if (value) {
        getPromotion()
      }
    })

    return {
      previewFiles,
      productImage,
      product,
      product_image,
      blob_image,
      imageLoading,
      urlImage,
      isShowDetailImage,
      removeLoading,
      showDetailImage,
      showSelectImage,
      alertRemoveImage,
      removeImage,
      isRemoveImage,
      icons: {
        mdiDeleteOutline, mdiCloudUploadOutline, mdiImageSearchOutline, mdiAlertOutline,
      },
    }
  },
}
</script>
