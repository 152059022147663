<template>
  <v-dialog
    v-model="isAddNewPromotionDrug"
    persistent
    max-width="1200"
  >
    <v-card>
      <v-card-title>
        {{ $t("add_promotion") }} {{ $t("drug_equipment") }}
      </v-card-title>
      <v-form
        ref="formAddPromotionDrug"
        @submit.prevent="addDrugPromotion"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="5"
              lg="4"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="product_name"
                    :label="$t('name')"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <span class="error--text">{{ $t("NoteGallery600600") }}</span>
                  <v-img
                    :src="blob_image"
                    height="400px"
                    class="rounded-lg elevation-2"
                    alt=""
                  ></v-img>
                  <input
                    ref="selectImage"
                    type="file"
                    style="display: none"
                    @change="previewFiles"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn
                    class="rounded-md elevation-3"
                    color="primary"
                    block
                    @click="$refs.selectImage.click()"
                  >
                    {{ $t("UploadImage") }}
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="product_vdo"
                    :label="$t('RecommendedvideoYoutubelink')"
                    outlined
                    dense
                    hide-details="auto"
                  >
                    <template v-slot:prepend>
                      <v-icon color="error">
                        {{ icons.mdiYoutube }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="7"
              lg="8"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="drug_category_id"
                    :no-data-text="$t('no_information')"
                    :items="categoryData"
                    :label="$t('category')"
                    outlined
                    dense
                    hide-details="auto"
                    :item-text="$i18n.locale"
                    item-value="value"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="drug_id_pri"
                    :no-data-text="$t('no_information')"
                    :items="drugEquipmentList"
                    :label="$t('drug_equipment')"
                    item-text="drug_name"
                    auto-select-first
                    :rules="[required]"
                    outlined
                    dense
                    :loading="drugLoading"
                    hide-details="auto"
                    return-object
                    item-value="drug_id_pri"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-autocomplete
                    v-model="drugorder_id_pri"
                    :no-data-text="$t('no_information')"
                    :items="lotList"
                    :label="$t('lot')"
                    item-text="textserach"
                    outlined
                    dense
                    hide-details="auto"
                    return-object
                    item-value="drugorder_id_pri"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_amount"
                    :label="$t('quantity')"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_unit"
                    :label="$t('unit')"
                    disabled
                    outlined
                    dense
                    hide-details="auto"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <v-text-field
                    v-model.trim="product_cost"
                    :label="$t('price_unit')"
                    outlined
                    dense
                    hide-details="auto"
                    readonly
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <v-text-field
                    v-model.trim="product_total_temp"
                    :label="$t('total_price')"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_total"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('promotion_price')"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_shop_point"
                    :label="$t('receive_points')"
                    outlined
                    dense
                    hide-details="auto"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-autocomplete
                    v-model.trim="product_group_id"
                    :items="promotionTypeList"
                    :label="$t('promotion_type')"
                    outlined
                    dense
                    hide-details="auto"
                    :no-data-text="$t('no_information')"
                    :item-text="$i18n.locale"
                    item-value="value"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-dialog
                    ref="selectDateStart"
                    v-model.trim="isShowDate1"
                    :return-value.sync="product_start"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model.trim="product_start"
                        hide-details
                        :label="$t('date')"
                        outlined
                        dense
                        :rules="[required]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model.trim="product_start"
                      class="set-font"
                      :locale="$i18n.locale"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="isShowDate1 = false"
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.selectDateStart.save(product_start)"
                      >
                        {{ $t("confirm") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-dialog
                    ref="selectDateEnd"
                    v-model.trim="isShowDate2"
                    :return-value.sync="product_end"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model.trim="product_end"
                        :label="$t('to')"
                        readonly
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[required]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model.trim="product_end"
                      class="set-font"
                      :locale="$i18n.locale"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="isShowDate2 = false"
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.selectDateEnd.save(product_end)"
                      >
                        {{ $t("confirm") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <label>{{ $t("detail") }}</label>
                  <quill-editor
                    ref="myTextEditor"
                    v-model="product_detail"
                    :options="editorOption"
                  >
                  </quill-editor>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model.trim="product_category_id"
                    :items="serviceTypeData"
                    :label="$t('service_type')"
                    outlined
                    dense
                    hide-details="auto"
                    item-text="product_category_name"
                    multiple
                    chips
                    small-chips
                    clearable
                    :no-data-text="$t('no_information')"
                    item-value="product_category_id"
                    :placeholder="$t('select')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t("save") }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:isAddNewPromotionDrug',false)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { mdiYoutube } from '@mdi/js'
import PromotionType from '@/@fake-db/data/PromotionType.json'
import category_drug_type from '@/@fake-db/data/category_drug_type.json'
import promotion from '@/api/WebsiteManageMent/promotion'
import { sumdate, sumdateEn } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  components: {
    quillEditor,
  },
  model: {
    prop: 'isAddNewPromotionDrug',
    event: 'update:isAddNewPromotionDrug',
  },
  props: {
    isAddNewPromotionDrug: Boolean,
  },
  setup(props, { emit }) {
    const moment = require('moment')
    const promotionTypeList = ref(PromotionType.data)
    const course_id_pri = ref('')
    const product_group_id = ref('')
    const product_name = ref('')
    const product_detail = ref('')
    const product_amount = ref('')
    const product_unit = ref('')
    const product_cost = ref('')
    const product_total = ref('')
    const product_total_temp = ref('')
    const product_shop_point = ref('')
    const product_vdo = ref('')
    const blob_image = ref('https://clinicv21.teendoistudio.com/assets/upload/product/none.png')
    const product_image = ref('')
    const drug_id_pri = ref('')
    const drugorder_id_pri = ref('')
    const dialog = ref(false)
    const editorOption = ref({ placeholder: '' })
    const lotList = ref([])
    const serviceTypeData = ref([])
    const product_category_id = ref([])
    const promotionTypeSelect = ref(1)
    const promotionTypeData = ref([])
    const drug_category_id = ref(0)
    const categoryData = ref(category_drug_type.data)
    const drugEquipmentList = ref([])
    const product_start = ref('')
    const product_end = ref('')
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)

    const drugLoading = ref(false)
    const formAddPromotionDrug = ref(null)
    const loading = ref(false)

    onMounted(() => {
      getServiceType()
    })

    const getDrugList = () => {
      drugLoading.value = true
      promotion.promotionDrugList(drug_category_id.value).then(res => {
        drugEquipmentList.value = res
        drugLoading.value = false
      })
    }

    const getServiceType = () => {
      promotion.promotionServiceTypeList().then(res => {
        serviceTypeData.value = res
      })
    }

    const getDurgOrderList = id => {
      promotion.promotionDrugOrderList(id).then(res => {
        lotList.value = res
        res.map(item => {
          item.textserach = `${item.druglot_name} Exp.: ${
            i18n.locale == 'th'
              ? sumdate(item.drugorder_expdate)
              : sumdateEn(item.drugorder_expdate)
          } ${i18n.t('balance')}: ${item.drugordert_total}`

          return item
        })
        drugorder_id_pri.value = res[0]
      })
    }

    const previewFiles = event => {
      product_image.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(
        event.target.files[event.target.files.length - 1],
      )
    }

    const addDrugPromotion = () => {
      const isFormValid = formAddPromotionDrug.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append(
        'drugorder_id_pri',
        drugorder_id_pri.value.drugorder_id_pri,
      )
      formData.append('product_group_id', product_group_id.value)
      formData.append('product_name', product_name.value)
      formData.append('product_detail', product_detail.value)
      formData.append('product_amount', +product_amount.value)
      formData.append('product_unit', product_unit.value)
      formData.append('product_price', +product_total_temp.value)
      formData.append('product_cost', +product_cost.value)
      formData.append('product_total', +product_total.value)
      formData.append('product_shop_point', product_shop_point.value)
      formData.append('product_start', product_start.value)
      formData.append('product_end', product_end.value)
      formData.append('product_vdo', product_vdo.value)
      product_category_id.value.forEach((item, index) => {
        formData.append(`product_category_id[${index}]`, item)
      })
      formData.append('product_image', product_image.value)

      promotion.promotionDrugAdd(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        emit('update:isAddNewPromotionDrug', false)
        loading.value = false
      })
    }

    watch(drug_id_pri, value => {
      getDurgOrderList(value.drug_id_pri)
    })

    watch(drug_category_id, () => {
      getDrugList()
    })

    watch(drugorder_id_pri, value => {
      product_amount.value = 1
      product_name.value = value.drug_name
      product_unit.value = value.drug_unit
      product_cost.value = value.drug_price
      product_shop_point.value = 0
      product_group_id.value = '1'
      product_start.value = moment(new Date()).format('YYYY-MM-DD')
      product_end.value = ''
      product_total_temp.value = parseFloat(value.drug_price) * +product_amount.value || 0
      product_total.value = parseFloat(value.drug_price) * +product_amount.value || 0
    })

    watch(product_amount, value => {
      product_total_temp.value = parseFloat(value) * +product_cost.value || 0
      product_total.value = parseFloat(value) * parseFloat(product_cost.value) || 0
    })

    watch(() => props.isAddNewPromotionDrug, value => {
      if (value) {
        course_id_pri.value = {}
        product_group_id.value = ''
        product_name.value = ''
        product_detail.value = ''
        product_amount.value = 0
        product_unit.value = ''
        product_cost.value = 0
        product_total.value = 0
        product_total_temp.value = 0
        product_shop_point.value = 0
        product_vdo.value = ''
        blob_image.value = ''
        product_image.value = ''
        drug_id_pri.value = ''
        drugorder_id_pri.value = ''
        product_start.value = ''
        product_end.value = ''
        promotionTypeSelect.value = 1
        product_category_id.value = []
        drug_category_id.value = 1
      }
    })

    return {
      promotionTypeList,
      course_id_pri,
      product_group_id,
      product_name,
      product_detail,
      product_amount,
      product_unit,
      product_cost,
      product_total,
      product_total_temp,
      product_shop_point,
      product_vdo,
      blob_image,
      product_image,
      drug_id_pri,
      drugorder_id_pri,
      dialog,
      editorOption,
      lotList,
      serviceTypeData,
      product_category_id,
      promotionTypeSelect,
      promotionTypeData,
      drug_category_id,
      categoryData,
      drugEquipmentList,
      product_start,
      product_end,
      isShowDate1,
      isShowDate2,
      previewFiles,
      drugLoading,
      formAddPromotionDrug,
      addDrugPromotion,
      loading,
      required,
      icons: {
        mdiYoutube,
      },
    }
  },
}
</script>
