import basicStatus from '@/@fake-db/data/basicStatus.json'
import ExpStatus from '@/@fake-db/data/ExpStatus.json'
import statusManageWebsite from '@/@fake-db/data/statusManageWebsite.json'
import promotion from '@/api/WebsiteManageMent/promotion'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function usePromotionManament() {
  const searchtext = ref('')
  const statusList = ref(basicStatus.data)
  const statusSelectd = ref('')
  const checkData = ref(statusManageWebsite.data)
  const checkSelect = ref('')
  const exData = ref(ExpStatus.data)
  const exSelect = ref('1')

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'product_id',
    },
    { text: i18n.t('picture'), value: 'product_image' },
    {
      text: i18n.t('name'),
      value: 'product_name',
      width: 200,
    },
    { text: i18n.t('list'), value: 'product_detail', width: 350 },
    {
      text: i18n.t('quantity'),
      value: 'product_amount',
      align: 'right',
    },
    { text: i18n.t('unit'), value: 'product_unit' },
    { text: i18n.t('price'), value: 'product_price', align: 'right' },

    {
      text: `${i18n.t('discount')} %`,
      value: 'product_percent',
      align: 'right',
      width: 120,
    },
    {
      text: i18n.t('promotion_price_point'),
      value: 'product_total',
      align: 'right',
      width: 180,
    },
    { text: i18n.t('start'), value: 'product_start', width: 120 },
    { text: i18n.t('end'), value: 'product_end', width: 120 },
    { text: i18n.t('type'), value: 'product_group_id' },
    { text: i18n.t('status'), value: 'product_active_id', align: 'center' },
    {
      text: i18n.t('status'),
      value: 'product_status_id',
      width: 150,
      align: 'center',
    },
    {
      text: i18n.t('option'),
      value: 'option',
      align: 'center fixed',
      width: 200,
    },
  ])

  // add Promotions drug and equipment

  const isAddNewPromotionCourse = ref(false)
  const isAddNewPromotionDrug = ref(false)

  const isEditPromotionCourse = ref(false)
  const isEditPromotionDrug = ref(false)
  const dataEdit = ref({})
  const prodcutId = ref('')

  const isEditGallery = ref(false)

  const isUpdateStatus = ref(false)
  const processUpdate = ref(false)
  const promotionStatus = ref(false)

  const isSendCheck = ref(false)
  const loadingSend = ref(false)

  const sendCheck = () => {
    loadingSend.value = true
    promotion
      .promotionActive({
        product_id: prodcutId.value,
      })
      .then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        isSendCheck.value = false
        loadingSend.value = false
        fetchDataTable()
      })
  }

  const updateStatus = () => {
    processUpdate.value = true
    promotion
      .promotionStatusUpdate({
        product_id: prodcutId.value,
        product_status_id: promotionStatus.value == '1' ? '2' : '1',
      })
      .then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        isUpdateStatus.value = false
        fetchDataTable()
        processUpdate.value = false
      })
  }

  const { promotionList } = promotion

  const fetchDataTable = e => {
    loading.value = true
    promotionList({
      searchtext: searchtext.value,
      product_status_id: statusSelectd.value,
      product_active_id: checkSelect.value,
      product_end_id: exSelect.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      totalPage.value = count_of_page
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      if (e == 'add') {
        options.value.page = count_of_page
      } else if (e == 'delete') {
        if (+options.value.page >= +count_of_page) {
          options.value.page = count_of_page
        }
      }
      loading.value = false
    })
  }

  watch([searchtext, statusSelectd, checkSelect, exSelect, options], (newvalue, oldvalue) => {
    if (
      newvalue[0] !== oldvalue[0] ||
      newvalue[1] !== oldvalue[1] ||
      newvalue[2] !== oldvalue[2] ||
      newvalue[3] !== oldvalue[3]
    ) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    searchtext,
    statusList,
    statusSelectd,
    checkData,
    checkSelect,
    exData,
    exSelect,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    totalPage,
    columns,
    isAddNewPromotionDrug,
    isAddNewPromotionCourse,
    isEditPromotionDrug,
    isEditPromotionCourse,
    dataEdit,
    prodcutId,
    isEditGallery,
    isUpdateStatus,
    processUpdate,
    promotionStatus,
    isSendCheck,
    loadingSend,
    sendCheck,
    updateStatus,
    fetchDataTable,
  }
}
