<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Manage Web Promotion') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-2 d-none d-md-block"
          @click="isAddNewPromotionCourse = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>    {{ $t("add_promotion") }} {{ $t("course") }}
        </v-btn>
        <v-btn
          color="primary"
          class="me-2  d-block d-md-none"
          icon
          fab
          outlined
          @click="isAddNewPromotionCourse = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-btn
          color="info"
          class="d-none d-md-block"
          @click="isAddNewPromotionDrug =true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>    {{ $t("add_promotion") }} {{ $t("drug_equipment") }}
        </v-btn>
        <v-btn
          color="info"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewPromotionDrug =true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="checkSelect"
            :items="checkData"
            :label="$t('checkingStatus')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="statusSelectd"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="exSelect"
            :items="exData"
            :label="$t('ExpirationStatus')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.product_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.product_image`]="{ item }">
          <v-avatar
            class="my-1 rounded-lg elevation-1"
            tile
          >
            <v-img :src="item.product_image" />
          </v-avatar>
        </template>
        <template v-slot:[`item.product_name`]="{ item }">
          <a
            :href="item.product_slug"
            target="_blank"
          >{{ item.product_name }}</a>
        </template>
        <template v-slot:[`item.product_detail`]="{ item }">
          {{ item.product_name }} ({{
            item.product_type_id == "1" ? item.course_id : item.drug_id
          }})
          <v-chip
            v-if="item.product_type_id == 2"
            small
            color="primary"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text px-3`"
          >
            <v-icon
              class="text-white"
              left
              small
            >
              {{ icons.mdiPill }}
            </v-icon> /
            <v-icon
              class="text-white"
              right
              small
            >
              {{ icons.mdiNeedle }}
            </v-icon>
          </v-chip>
          <v-chip
            v-else
            small

            color="accent"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg accent--text px-2`"
          >
            <v-icon
              class="text-white"
              small
            >
              {{ icons.mdiAutoFix }}
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.product_price`]="{ item }">
          {{ item.product_price | formatPrice }}
        </template>
        <template v-slot:[`item.product_total`]="{ item }">
          {{ item.product_total | formatPrice }}
        </template>
        <template v-slot:[`item.product_start`]="{ item }">
          <ConverseDate :date="item.product_start" />
        </template>
        <template v-slot:[`item.product_end`]="{ item }">
          <ConverseDate :date="item.product_end" />
        </template>
        <template v-slot:[`item.product_group_id`]="{ item }">
          {{ item.product_group_id == "1" ? $t("normal") : $t("birth_month_promotion") }}
        </template>
        <template v-slot:[`item.product_active_id`]="{ item }">
          <v-chip
            v-if="item.product_active_id == '0'"
            color="error"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
            small
          >
            {{ $t("waiting_check") }}
          </v-chip>
          <v-chip
            v-if="item.product_active_id == '1'"
            color="error"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
            small
          >
            {{ $t("checked") }}
          </v-chip>
          <v-chip
            v-if="item.product_active_id == '2'"
            color="error"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
            small
          >
            {{ $t("failed") }}
          </v-chip>
        </template>
        <template v-slot:[`item.product_status_id`]="{ item }">
          <StatusBlock :status="item.product_status_id" />
        </template>
        <template v-slot:[`item.option`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="(item.product_type_id == 2?isEditPromotionDrug = true:isEditPromotionCourse = true); prodcutId = item.product_id"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="prodcutId = item.product_id; isEditGallery = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiImageEditOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}{{ $t("gallery") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="prodcutId = item.product_id; promotionStatus=item.product_status_id;isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.product_status_id=='2'?icons.mdiCheck: icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.product_status_id == '2'? $t("normal"): $t('suspend') }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="prodcutId = item.product_id;isSendCheck = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiSendCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("send_check") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>

    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ promotionStatus ==1? $t('confirmSuspendPromotion'):$t('cancelSuspendPromotion') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processUpdate"
            :disabled="processUpdate"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isSendCheck"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          {{ $t("confirm_send_check") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loadingSend"
            :disabled="loadingSend"
            @click="sendCheck"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isSendCheck = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddNewPromotionCourse
      v-model="isAddNewPromotionCourse"
      @onAdd="fetchDataTable"
    />
    <AddNewPromotionDrugEquipment
      v-model="isAddNewPromotionDrug"
      @onAdd="fetchDataTable"
    />
    <EditPromotionCourse
      v-model="isEditPromotionCourse"
      :product-id="prodcutId"
      @onUpdate="fetchDataTable"
    />
    <EditPromotionDrug
      v-model="isEditPromotionDrug"
      :product-id="prodcutId"
      @onUpdate="fetchDataTable"
    />
    <EditGallery
      v-model="isEditGallery"
      :product-id="prodcutId"
    />
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiCheck, mdiSendCheck, mdiDeleteOutline, mdiImageEditOutline, mdiPill, mdiNeedle, mdiAutoFix,
} from '@mdi/js'
import usePromotionManament from './usePromotionManament'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import { formatPrice } from '@/plugins/filters'
import AddNewPromotionCourse from './AddNewPromotionCourse.vue'
import AddNewPromotionDrugEquipment from './AddNewPromotionDrugEquipment.vue'
import EditPromotionCourse from './EditPromotionCourse.vue'
import EditPromotionDrug from './EditPromotionDrug.vue'
import EditGallery from './EditGallery.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Pagination,
    ConverseDate,
    StatusBlock,
    AddNewPromotionCourse,
    AddNewPromotionDrugEquipment,
    EditPromotionCourse,
    EditPromotionDrug,
    EditGallery,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...usePromotionManament(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiCheck,
        mdiSendCheck,
        mdiDeleteOutline,
        mdiImageEditOutline,
        mdiPill,
        mdiNeedle,
        mdiAutoFix,
      },
    }
  },
}
</script>
