var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"1100"},model:{value:(_vm.isEditGallery),callback:function ($$v) {_vm.isEditGallery=$$v},expression:"isEditGallery"}},[_c('v-card',{attrs:{"loading":_vm.imageLoading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('gallery'))+" "),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t("NoteGallery600600")))])]),_c('v-card-text',[_c('v-row',[_c('input',{ref:"selectImage",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.previewFiles}}),_vm._l((_vm.productImage),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',[_c('v-img',{attrs:{"lazy-src":item,"src":item}}),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","x-small":"","fab":""},on:{"click":function($event){return _vm.showDetailImage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiImageSearchOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("look_at_the_image")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","x-small":"","fab":""},on:{"click":function($event){_vm.showSelectImage(index+1);_vm.$refs.selectImage.click()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudUploadOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("upload_pictures")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"error","x-small":"","fab":""},on:{"click":function($event){return _vm.alertRemoveImage(index+1)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("DeleteImage")))])]),_c('v-spacer')],1)],1)],1)})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('update:isEditGallery',false)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1),_c('v-dialog',{model:{value:(_vm.isShowDetailImage),callback:function ($$v) {_vm.isShowDetailImage=$$v},expression:"isShowDetailImage"}},[_c('v-img',{attrs:{"src":_vm.urlImage,"lazy-src":_vm.urlImage},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.isRemoveImage),callback:function ($$v) {_vm.isRemoveImage=$$v},expression:"isRemoveImage"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-icon',{staticClass:"me-1",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOutline)+" ")]),_vm._v(" "+_vm._s(_vm.$t('confirmDeleteImage'))+" "),_c('v-spacer')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","loading":_vm.removeLoading,"disabled":_vm.removeLoading},on:{"click":_vm.removeImage}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.isRemoveImage = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }