<template>
  <v-dialog
    v-model="isEditPromotionCourse"
    persistent
    max-width="1200"
  >
    <v-card :loading="loading">
      <v-card-title>
        {{ $t('editPromotionCourse') }}
      </v-card-title>
      <v-form
        ref="formEditPromotionCourse"
        @submit.prevent="updatePromotionCourse"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="5"
              lg="4"
            >
              <v-text-field
                v-model="product_name"
                :label="$t('name')"
                readonly
                outlined
                dense
              ></v-text-field>
              <input
                ref="selectImage"
                type="file"
                style="display: none"
                @change="previewFiles"
              />
              <p class="error--text">
                {{ $t("NoteGallery600600") }}
              </p>
              <v-img
                :src="blob_image"
                height="400px"
                class="rounded-lg"
                alt=""
              ></v-img>
              <v-btn
                class="mt-3"
                color="primary"
                block
                @click="$refs.selectImage.click()"
              >
                {{ $t("UploadImage") }}
              </v-btn>
              <v-text-field
                v-model.trim="product_vdo"
                class="mt-3"
                :label="$t('RecommendedvideoYoutubelink')"
                outlined
                dense
              >
                <template v-slot:prepend>
                  <v-icon color="error">
                    {{ icons.mdiYoutube }}
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="7"
              lg="8"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="categorySelect"
                    :no-data-text="$t('no_information')"
                    :items="categoryData"
                    :label="$t('choose_course_group')"
                    dense
                    outlined
                    hide-details="auto"
                    item-text="coursegroup_name"
                    item-value="coursegroup_id"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="course_id_pri"
                    :no-data-text="$t('no_information')"
                    dense
                    outlined
                    hide-details="auto"
                    :items="courseData"
                    :rules="[required]"
                    :label="$t('choose_a_course')"
                    item-text="course_name"
                    return-object
                    item-value="course_id_pri"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_amount"
                    :label="$t('quantity')"
                    type="number"
                    disabled
                    hide-details="auto"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_unit"
                    :label="$t('unit')"
                    readonly
                    outlined
                    hide-details="auto"
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_cost"
                    :label="$t('course_price')"
                    readonly
                    disabled
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_total"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('price')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-text-field
                    v-model.trim="product_shop_point"
                    :label="$t('receive_points')"
                    outlined
                    dense
                    hide-details="auto"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <v-autocomplete
                    v-model.trim="product_group_id"
                    :items="promotionTypeList"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('promotion_type')"
                    :no-data-text="$t('no_information')"
                    :item-text="$i18n.locale"
                    item-value="value"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-dialog
                    ref="selectDate1"
                    v-model.trim="isShowDate1"
                    :return-value.sync="product_start"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model.trim="product_start"
                        :label="$t('date')"
                        outlined
                        dense
                        :rules="[required]"
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model.trim="product_start"
                      class="set-font"
                      :locale="$i18n.locale"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.selectDate1.save(product_start)"
                      >
                        {{ $t("confirm") }}
                      </v-btn>
                      <v-btn
                        text
                        color="secondary"
                        @click="isShowDate1 = false"
                      >
                        {{ $t("cancel") }}
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-dialog
                    ref="selectDate2"
                    v-model.trim="isShowDate2"
                    :return-value.sync="product_end"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model.trim="product_end"
                        class="set-font"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[required]"
                        :label="$t('to')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model.trim="product_end"
                      class="set-font"
                      :locale="$i18n.locale"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.selectDate2.save(product_end)"
                      >
                        {{ $t("confirm") }}
                      </v-btn>
                      <v-btn
                        text
                        color="secondary"
                        @click="isShowDate2 = false"
                      >
                        {{ $t("cancel") }}
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <label>{{ $t("detail") }}</label>
                  <quill-editor
                    ref="myTextEditor"
                    v-model="product_detail"
                    :options="editorOption"
                  >
                  </quill-editor>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model.trim="product_category_id"
                    :items="serviceTypeData"
                    :label="$t('service_type')"
                    item-text="product_category_name"
                    multiple
                    outlined
                    dense
                    hide-details="auto"
                    chips
                    small-chips
                    clearable
                    :no-data-text="$t('no_information')"
                    item-value="product_category_id"
                    :placeholder="$t('select')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loadingUpdate"
            :disabled="loadingUpdate"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:isEditPromotionCourse',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import { mdiYoutube } from '@mdi/js'
import { quillEditor } from 'vue-quill-editor'
import courseGroup from '@/api/courseManagement/courseGroup'
import PromotionType from '@/@fake-db/data/PromotionType.json'
import promotion from '@/api/WebsiteManageMent/promotion'
import { i18n } from '@/plugins/i18n'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  components: {
    quillEditor,
  },
  model: {
    prop: 'isEditPromotionCourse',
    event: 'update:isEditPromotionCourse',
  },
  props: {
    isEditPromotionCourse: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const moment = require('moment')
    const promotionTypeList = ref(PromotionType.data)
    const course_id_pri = ref('')
    const product_group_id = ref('')
    const product_name = ref('')
    const product_detail = ref('')
    const product_amount = ref('')
    const product_unit = ref('')
    const product_cost = ref('')
    const product_total = ref('')
    const product_shop_point = ref('')
    const product_end = ref('')
    const product_vdo = ref('')
    const product_image = ref('')
    const blob_image = ref('https://clinicv21.teendoistudio.com/assets/upload/product/none.png')
    const dialog = ref(false)
    const serviceTypeData = ref([])
    const product_category_id = ref('')
    const promotionTypeSelect = ref(1)
    const promotionTypeData = ref([])
    const categorySelect = ref('')
    const courseData = ref([])
    const categoryData = ref([])
    const product_start = ref('')
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const editorOption = ref({
      placeholder: '',
    })
    const formEditPromotionCourse = ref(null)
    const loading = ref(true)
    const loadingUpdate = ref(false)

    const { courseGroupList } = courseGroup
    const {
      promotionServiceTypeList, promotionGet, promotionCourseList, promotionCourseUpdate,
    } = promotion

    const getPromotion = () => {
      loading.value = true
      promotionGet(props.productId).then(res => {
        mapData(res)
      })
    }

    const mapData = product => {
      product_category_id.value = []
      course_id_pri.value = courseData.value.find(course => course.course_id_pri == product.course_id_pri)
      product_group_id.value = product.product_group_id
      product_name.value = product.product_name
      product_detail.value = product.product_detail
      product_amount.value = product.product_amount
      product_unit.value = product.product_unit
      product_cost.value = product.product_cost
      product_total.value = product.product_total
      product_shop_point.value = product.product_shop_point
      product_start.value = product.product_start
      product_end.value = product.product_end
      product_vdo.value = product.product_vdo
      if (product.product_category) {
        product.product_category.forEach(item => {
          product_category_id.value.push(item.product_category_id)
        })
      }
      product_image.value = product.product_image ? product.product_image : ''
      blob_image.value = product.product_image ? product.product_image : ''
      loading.value = false
    }

    const getCourseList = () => {
      promotionCourseList(categorySelect.value).then(res => {
        courseData.value = res
      })
    }
    onMounted(() => {
      getCourseGroupList()
      getServiceType()
    })

    const previewFiles = event => {
      product_image.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(
        event.target.files[event.target.files.length - 1],
      )
    }

    const getServiceType = () => {
      promotionServiceTypeList().then(res => {
        serviceTypeData.value = res
      })
    }

    const getCourseGroupList = () => {
      courseGroupList({
        searchtext: '',
        coursegroup_status_id: 1,
      }).then(res => {
        categoryData.value = res
        categoryData.value.unshift({
          coursegroup_id: '',
          coursegroup_name: i18n.t('all'),
        })
        categorySelect.value = ''
        getCourseList()
      })
    }

    const updatePromotionCourse = () => {
      const isFormValid = formEditPromotionCourse.value.validate()
      if (!isFormValid) return
      loadingUpdate.value = true
      const formData = new FormData()
      formData.append('product_id', props.productId)
      formData.append('course_id_pri', course_id_pri.value.course_id_pri)
      formData.append('product_group_id', product_group_id.value)
      formData.append('product_name', product_name.value)
      formData.append('product_detail', product_detail.value)
      formData.append('product_amount', product_amount.value)
      formData.append('product_unit', product_unit.value)
      formData.append('product_cost', product_cost.value)
      formData.append('product_total', product_total.value)
      formData.append('product_shop_point', product_shop_point.value)
      formData.append('product_start', product_start.value)
      formData.append('product_end', product_end.value)
      formData.append('product_vdo', product_vdo.value)
      product_category_id.value.forEach((item, index) => {
        formData.append(`product_category_id[${index}]`, item)
      })
      formData.append('product_image', product_image.value)
      promotionCourseUpdate(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isEditPromotionCourse', false)
        emit('onUpdate')
        loadingUpdate.value = false
      })
    }

    watch(() => props.isEditPromotionCourse, value => {
      if (value) {
        getPromotion()
      }
    })

    watch(categorySelect, () => {
      getCourseList()
    })

    watch(course_id_pri, value => {
      product_amount.value = 1
      product_unit.value = i18n.t('times')
      product_shop_point.value = 0
      product_cost.value = value.course_price
      product_total.value = value.course_price
      product_name.value = value.course_name
      product_start.value = moment(new Date()).format('YYYY-MM-DD')
      product_group_id.value = '1'
    })

    return {
      promotionTypeList,
      course_id_pri,
      product_group_id,
      product_name,
      product_detail,
      product_amount,
      product_unit,
      product_cost,
      product_total,
      product_shop_point,
      product_end,
      product_vdo,
      product_image,
      blob_image,
      dialog,
      serviceTypeData,
      product_category_id,
      promotionTypeSelect,
      promotionTypeData,
      categorySelect,
      courseData,
      categoryData,
      product_start,
      isShowDate1,
      isShowDate2,
      editorOption,
      loading,
      formEditPromotionCourse,
      loadingUpdate,
      required,
      previewFiles,
      updatePromotionCourse,

      icons: {
        mdiYoutube,
      },

    }
  },
}
</script>
